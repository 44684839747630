import { useState } from 'react'
import styled from 'styled-components'

const ClipboardContainer = styled.div`
  color: white;
  border: 1px solid;
  border-radius: 0.5rem; /* 8px to rem */
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`

const ClipboardInput = styled.input`
  padding: 0.75rem 1rem; /* 12px 16px to rem */
  display: block;
  border-radius: 0.5rem; /* 8px to rem */
  font-size: 0.875rem; /* 14px to rem */
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.3) 0%,
    rgba(41, 171, 226, 0.3) 40%,
    rgba(0, 104, 55, 0.5) 100%
  );
  color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &::placeholder {
    color: white;
  }
`

const Mask = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s;
  pointer-events: none;
`

interface CopyInputProps {
  value: string
}

const CopyInput = (props: CopyInputProps) => {
  const { value } = props
  const [isHovered, setIsHovered] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(value)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 700)
  }

  return (
    <ClipboardContainer
      id="hs-clipboard-basic"
      onClick={handleCopy}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ClipboardInput
        id="hs-clipboard-input"
        type="text"
        value={value}
        readOnly
      />
      <Mask isVisible={isHovered || isCopied}>
        {isCopied ? 'Copied to clipboard' : 'Copy'}
      </Mask>
    </ClipboardContainer>
  )
}

export default CopyInput
