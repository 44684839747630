import React from 'react'
import styled from 'styled-components'
import { NAVIGATION_HEIGHT } from './Navigation'

const AnchorContainer = styled.div``

const Container = styled.div`
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  margin-top: 3rem;
  transform: translateY(${NAVIGATION_HEIGHT}rem);
`

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`

const CircleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  justify-content: center;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Circle = styled.div`
  width: clamp(12.5rem, 28vw, 21.875rem);
  height: clamp(12.5rem, 28vw, 21.875rem);
  border-radius: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  /* background-color: rgba(255, 255, 255, 0.4); */
  background-color: rgba(0, 0, 0, 0.6);
  border: 0.125rem solid white;
  backdrop-filter: blur(20px);

  h3 {
    font-weight: bold;
    font-size: clamp(1.14rem, 2.56vw, 2rem);
    margin-bottom: 0.6rem;
  }

  p {
    font-size: clamp(0.86rem, 1.92vw, 1.5rem);
  }

  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
  }

  @media (max-width: 576px) {
    width: 350px;
    height: 350px;
    h3 {
      font-weight: bold;
      font-size: 2rem;
      margin-bottom: 0.6rem;
    }

    p {
      font-size: 1.5rem;
    }
    background-color: rgba(0, 0, 0, 0.3);
  }
`

const HowToBuy: React.FC = () => {
  return (
    <AnchorContainer id="howtobuy">
      <Container>
        <Title>How to buy</Title>
        <CircleContainer>
          <Circle>
            <h3>Step 1: </h3>
            <p>Press "Buy Now" above and connect wallet</p>
          </Circle>
          <Circle>
            <h3>Step 2: </h3>
            <p>Enter amount of SOL to swap for $PEPEvx</p>
          </Circle>
          <Circle>
            <h3>Step 3: </h3>
            <p>Hit "Buy Now" again and confirm transaction</p>
          </Circle>
        </CircleContainer>
      </Container>
    </AnchorContainer>
  )
}

export default HowToBuy
