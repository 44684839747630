import styled from 'styled-components'
import { EMAIL_ACC } from '../../../const/email'
import { NAVIGATION_HEIGHT } from './Navigation'

const FooterContainer = styled.footer`
  background-color: rgba(0, 0, 0, 1);
  color: white;
  padding: 1rem 0;
  margin-top: 3rem;
  transform: translateY(${2 * NAVIGATION_HEIGHT}rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 3rem;
  height: 15rem;
`

const CopyrightText = styled.p`
  font-size: clamp(0.7rem, 2vw, 1.1rem);
  color: white;
  margin-bottom: 1rem;
  text-align: center;
`

const RightsText = styled.p`
  font-size: clamp(0.5rem, 1.45vw, 0.8rem);
  color: white;
  margin-bottom: 1rem;
  text-align: center;
`

const DisclaimerText = styled.p`
  font-size: clamp(0.3rem, 0.9vw, 0.5rem);
  text-align: center;
  color: grey;
  width: 30%;
  margin-bottom: 1rem;
  &:last-child {
    font-size: clamp(0.4rem, 0.9vw, 0.6rem);
    margin-bottom: 0;
  }
`

const Footer = () => {
  return (
    <FooterContainer>
      <CopyrightText>COPYRIGHT © 2024 PEPEVERSE.</CopyrightText>
      <RightsText>ALL RIGHTS RESERVED.</RightsText>
      <DisclaimerText>
        DISCLAIMER: PEPEvx IS A MEME COIN WITH SPECULATIVE VALUE DERIVED ONLY
        FROM THE UNIVERSAL CULTURAL CAPITAL OF THE PEPE MEME. CRYPTO PRICES
        FLUCTUATE WILDLY. APE RESPONSIBLY.
      </DisclaimerText>
      <DisclaimerText>{EMAIL_ACC}</DisclaimerText>
    </FooterContainer>
  )
}

export default Footer
