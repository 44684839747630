import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import ITCKRIST from './fonts/ITCKRIST.ttf'
import Home from './views/Homepage'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'ITCKRIST';
    src: url(${ITCKRIST}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-family: 'ITCKRIST', sans-serif;
    background-color: black;
    color: white;
  }
`

const App = () => {
  return (
    <Router>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Add more routes for other pages */}
      </Routes>
    </Router>
  )
}

export default App
