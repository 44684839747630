import React from 'react'
import styled from 'styled-components'
import tokenomicsImage from '../../../images/tokenomics.png'
import { NAVIGATION_HEIGHT } from './Navigation'

const AnchorContainer = styled.div``

const SectionContainer = styled.div`
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translateY(${NAVIGATION_HEIGHT}rem);
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translateY(${NAVIGATION_HEIGHT / 2}rem);
  flex-direction: row;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
    flex-direction: column;
  }

  @media (max-width: 730px) {
    flex-direction: column;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
`

const SectionTitle = styled.div`
  color: white;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
`

const ContentBox = styled.div`
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(12px);
  border-radius: 2.5rem;
  padding: 1.5rem 3rem;
  color: white;
  width: 35%;
  border: 0.125rem solid white;
  margin: 0 auto;
  transform: translateX(25%);

  @media (max-width: 1440px) {
    transform: translateX(20%);
  }

  @media (max-width: 1200px) {
    transform: translateX(20%);
  }

  @media (max-width: 992px) {
    transform: translateX(0%);
    margin-bottom: 0;
    width: 80%;
    margin-bottom: 4rem;
  }

  @media (max-width: 730px) {
    transform: translateX(0%);
    margin-bottom: 0;
    width: 80%;
    margin-bottom: 4rem;
  }

  @media (max-width: 576px) {
    transform: translateX(0%);
    margin-bottom: 0;
    width: 80%;
    margin-bottom: 4rem;
    padding: 1.5rem 1.5rem;
  }
`

const Title = styled.h1`
  font-size: clamp(1rem, 2vw, 1.5rem);
  margin-bottom: 0.8rem;
  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
    font-size: 1.5rem;
  }

  @media (max-width: 730px) {
    font-size: 1.5rem;
  }

  @media (max-width: 576px) {
    font-size: 1.1rem;
  }
`

const Text = styled.p`
  font-size: clamp(0.66rem, 1.33vw, 1rem);
  margin-bottom: 0.4375rem;
  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
    font-size: 1rem;
  }

  @media (max-width: 730px) {
    font-size: 1rem;
  }

  @media (max-width: 576px) {
    font-size: 0.8rem;
  }
`

const Chart = styled.div`
  width: 100%;
  max-width: 40rem;
  display: flex;
  justify-content: center;
  margin: 0 auto; /* Center horizontally */
`

const Image = styled.img`
  width: 100%;
  border-radius: 50%;
  margin: 0 auto;
  transform: scale(1.2);

  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
    transform: translateX(2%);
  }

  @media (max-width: 992px) {
    transform: translateX(0%);
  }

  @media (max-width: 730px) {
    transform: translateX(0%);
  }

  @media (max-width: 576px) {
    transform: translateX(0%);
    transform: scale(1.4);
  }
`

const TokenomicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8125rem;
  margin-top: 1.4rem;
`

const TokenomicsRow = styled.div`
  display: flex;
  font-size: clamp(0.53rem, 1.08vw, 0.8125rem);
  background-color: rgb(216, 216, 216, 0.35);
  border-radius: 0.5rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  &:last-child {
    border-bottom: none;
  }
  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
    font-size: 0.8125rem;
  }

  @media (max-width: 730px) {
    font-size: 0.8125rem;
  }

  @media (max-width: 576px) {
    font-size: 0.8125rem;
  }

  @media (max-width: 500px) {
    font-size: clamp(0.6rem, 2.3vw, 0.8125rem);
  }
`

const TokenomicsItem = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const ColorCircle = styled.div<{ color: string }>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  background-color: ${({ color }) => color};
  margin-right: 0.5rem;
`

const Label = styled.span`
  color: #ffffff;
  text-align: left;
`

const PercentageValue = styled.span`
  color: #ffffff;
  width: 20%;
  text-align: center;
`

const NumTokensValue = styled.span`
  color: #ffffff;
  width: 30%;
  text-align: center;
  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
  }

  @media (max-width: 576px) {
  }
`

const Tokenomics: React.FC = () => {
  const data = [
    {
      label: 'DEX Liquidity',
      percentage: '70%',
      value: '700,000,000',
      color: '#03589E',
    },
    {
      label: 'Project Funds',
      percentage: '10%',
      value: '100,000,000',
      color: '#669C00',
    },
    {
      label: 'Ecosystem Funds',
      percentage: '10%',
      value: '100,000,000',
      color: '#EF256C',
    },
    {
      label: 'CEX and Marketing',
      percentage: '10%',
      value: '100,000,000',
      color: '#F46969',
    },
  ]

  return (
    <AnchorContainer id="tokenomics">
      <SectionContainer>
        <SectionTitle>Tokenomics</SectionTitle>
        <ContentContainer>
          <ContentBox>
            <Title>
              Pepeverse ( $PEPEvx ) has a total supply of 1,000,000,000 Tokens
            </Title>
            <Text>LP Burned</Text>
            <Text>100% Community Owned</Text>
            <Text>0 / 0 Tax</Text>
            <TokenomicsContainer>
              {data.map((item) => (
                <TokenomicsRow key={item.label}>
                  <TokenomicsItem>
                    <ColorCircle color={item.color} />
                    <Label>{item.label}</Label>
                  </TokenomicsItem>
                  <PercentageValue>{item.percentage}</PercentageValue>
                  <NumTokensValue>{item.value}</NumTokensValue>
                </TokenomicsRow>
              ))}
            </TokenomicsContainer>
          </ContentBox>

          <Chart>
            <Image src={tokenomicsImage} alt="Tokenomics Chart" />
          </Chart>
        </ContentContainer>
      </SectionContainer>
    </AnchorContainer>
  )
}

export default Tokenomics
