import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import CopyInput from '../../../components/CopyInput'
import { CONTRACT_ADDRESS } from '../../../const/contract'
import {
  BUY_NOW_URL,
  DEX_SCREENER_URL,
  DEX_TOOLS_URL,
  TELEGRAM_URL,
  TWITTER_URL,
} from '../../../const/url'
import dexscreenerIcon from '../../../images/dexscreener_icon.png'
import dextoolsIcon from '../../../images/dextools_icon.png'
import homepageBackground from '../../../images/homepage_background.png'
import homepageBackgroundMobile from '../../../images/homepage_background_mobile.jpeg'
import homepageBackgroundShiftRight from '../../../images/homepage_background_shift_right.png'
import telegramIcon from '../../../images/telegram_icon.png'
import twitterIcon from '../../../images/twitter_icon.png'
import { NAVIGATION_HEIGHT } from './Navigation'

const HomePageContainer = styled.div`
  background-image: url(${homepageBackground});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  color: white;
  justify-content: flex-start;
  padding-left: 13.5%;
  @media (max-width: 1440px) {
    background-image: url(${homepageBackgroundShiftRight});
    background-position: calc(50% + 4rem)
      calc(50% + ${NAVIGATION_HEIGHT / 2}rem);
  }

  @media (max-width: 1200px) {
    background-image: url(${homepageBackgroundShiftRight});
  }

  @media (max-width: 992px) {
    background-image: url(${homepageBackgroundShiftRight});
  }

  @media (max-width: 730px) {
    background-image: url(${homepageBackgroundShiftRight});
  }

  @media (max-width: 576px) {
    background-image: url(${homepageBackgroundMobile});
    padding-left: 0;
    justify-content: center;
    height: 116vh;
    background-size: 100% 100%;
    background-position: center calc(50% + ${NAVIGATION_HEIGHT / 2 - 1}rem);
  }
`

interface CardProps {
  cardWidth: number
}

const Card = styled.div<CardProps>`
  background: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.4) 0%,
    rgba(41, 171, 226, 0.4) 40%,
    rgba(0, 104, 55, 0.6) 100%
  );
  transform: translateY(${NAVIGATION_HEIGHT / 2}rem);
  border-radius: 2rem;
  padding: 2.9rem 3.2rem;
  text-align: center;
  border: 0.125rem solid white;
  margin-right: auto;
  box-shadow: 0 0 0.9375rem #00000080;
  backdrop-filter: blur(0.1875rem);
  width: ${(props) => props.cardWidth}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1440px) {
    padding: 1.4rem 3.2rem;
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
  }

  @media (max-width: 576px) {
    backdrop-filter: blur(5px);
    padding: 1rem 3.2rem;
    margin: 0 auto;
    transform: translateY(10.6rem);
  }
`

const Title = styled.div`
  /* 3.3 */
  font-size: clamp(2.4rem, 4vw, 3.75rem);
  width: fit-content;
`

const SubTitle = styled.div`
  font-size: clamp(1.45rem, 3vw, 2.3125rem);
  /* margin-top: -1rem; */
`

const TickerTitle = styled.div`
  font-weight: bold !important;
  font-size: clamp(0.7rem, 1.5vw, 1.1rem);
  margin-top: -1rem;
  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
  }

  @media (max-width: 576px) {
  }
`

const Text = styled.div`
  font-weight: bold !important;
  margin: 1rem 0;
  font-size: clamp(0.96rem, 2vw, 1.5rem);
  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
  }

  @media (max-width: 576px) {
    margin: 0.7rem 0;
  }
`

const BuyButton = styled.button`
  color: black;
  background: white;
  border: 0.0625rem solid white;
  border-radius: 2rem;
  padding: 0.625rem 1.875rem;
  cursor: pointer;
  font-size: clamp(0.875rem, 1.2vw + 0.5rem, 1.25rem);
  margin-top: 1.25rem;
  transition:
    background 0.2s ease-in-out,
    color 0.2s ease-in-out,
    transform 0.2s ease-in-out;

  &:hover,
  &:focus {
    background: #ffffffc8;
    color: black;
    transform: scale(1.1);
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  margin-top: 1.25rem;
  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
  }

  @media (max-width: 576px) {
    margin-top: 0rem;
  }
`

const Icon = styled.img`
  width: clamp(2.5rem, 3vw + 1.5rem, 3rem);
  transition: transform 0.2s ease-in-out;

  &:hover,
  &:focus {
    transform: scale(1.2);
  }
`

const HomePage: React.FC = () => {
  const titleRef = useRef<HTMLDivElement>(null)
  const [cardWidth, setCardWidth] = useState<number>(0)

  useEffect(() => {
    const handleResize = () => {
      if (titleRef.current) {
        const titleWidth = titleRef.current.offsetWidth
        const padding =
          window.innerWidth < 576 ? 32 : window.innerWidth < 768 ? 48 : 64
        setCardWidth(titleWidth + padding * 2)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <HomePageContainer>
      <Card cardWidth={cardWidth}>
        <Title ref={titleRef}>Pepeverse</Title>
        <TickerTitle>( $PEPEvx )</TickerTitle>
        {BUY_NOW_URL !== '' && <SubTitle>is now live</SubTitle>}
        {BUY_NOW_URL !== '' && <Text>Token Address:</Text>}
        {BUY_NOW_URL !== '' && <CopyInput value={CONTRACT_ADDRESS} />}
        {BUY_NOW_URL !== '' ? (
          <a href={BUY_NOW_URL} target="_blank">
            <BuyButton>Buy Now !</BuyButton>
          </a>
        ) : (
          <BuyButton>Coming Soon !</BuyButton>
        )}
        <Text>Follow our socials for announcements</Text>
        <IconContainer>
          <a href={TELEGRAM_URL} target="_blank" rel="noopener noreferrer">
            <Icon src={telegramIcon} alt="Telegram" />
          </a>
          <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
            <Icon src={twitterIcon} alt="Twitter" />
          </a>
          {DEX_SCREENER_URL !== '' && (
            <a href={DEX_SCREENER_URL} target="_blank">
              <Icon src={dexscreenerIcon} alt="Twitter" />
            </a>
          )}
          {DEX_TOOLS_URL !== '' && (
            <a href={DEX_TOOLS_URL} target="_blank">
              <Icon src={dextoolsIcon} alt="Twitter" />
            </a>
          )}
        </IconContainer>
      </Card>
    </HomePageContainer>
  )
}

export default HomePage
