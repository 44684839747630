import React, { useEffect } from 'react'
import styled from 'styled-components'
import pepeMoon from '../../../images/pepe_moon.png'
import roadmapBackground from '../../../images/roadmap_background.png'
import rocketPepe from '../../../images/rocket_pepe.png'
import wormhole from '../../../images/wormhole.png'
import { NAVIGATION_HEIGHT } from './Navigation'

const RoadmapContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: url(${roadmapBackground});
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
`

const Title = styled.h1`
  transform: translateY(${NAVIGATION_HEIGHT / 2 + 0.3}rem);
  color: white;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 4rem;
`

const Phase = styled.div<{ top: string; left: string }>`
  position: absolute;
  width: clamp(15rem, 20vw, 25rem);
  height: clamp(15rem, 20vw, 25rem);
  background-image: url(${wormhole});
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
    position: static;
    display: block;
    margin: 1.25rem auto;
  }

  @media (max-width: 576px) {
    position: static;
    display: block;
    margin: 1.25rem auto;
  }
`

const PhaseContent = styled.div`
  transform: translateY(-20%);

  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
    position: static;
    display: block;
    margin: 0 auto;
    transform: translateY(90%);
  }

  @media (max-width: 576px) {
    position: static;
    display: block;
    margin: 0 auto;
    transform: translateY(90%);
  }

  h3 {
    font-weight: bold;
    font-size: clamp(0.9rem, 1.2vw, 1.5rem);
    margin-bottom: 0.6rem;
  }

  p {
    font-size: clamp(0.48rem, 0.64vw, 0.8rem);
    margin: 0.25rem 0;
  }
`

const MoonImage = styled.img<{ top?: string; left?: string; width?: string }>`
  position: absolute;
  top: ${({ top }) => top || 'auto'};
  left: ${({ left }) => left || 'auto'};
  width: clamp(14rem, 22vw, 27rem);

  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
    position: static;
    display: block;
    margin: 0 auto;
  }

  @media (max-width: 576px) {
    position: static;
    display: block;
    margin: 0 auto;
  }
`

const RocketImage = styled.img<{ top?: string; left?: string; width?: string }>`
  position: absolute;
  top: ${({ top }) => top || 'auto'};
  left: ${({ left }) => left || 'auto'};
  width: clamp(9rem, 12vw, 15rem);
  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
    position: static;
    display: block;
    margin: 0 auto;
  }

  @media (max-width: 576px) {
    position: static;
    display: block;
    margin: 0 auto;
  }
`

const Roadmap: React.FC = () => {
  useEffect(() => {
    ScrollReveal().reveal('.phase1', {
      delay: 100,
      reset: true,
      origin: 'left',
      distance: '20rem',
      duration: 700,
    })
    ScrollReveal().reveal('.phase2', {
      delay: 250,
      reset: true,
      origin: 'left',
      distance: '20rem',
      duration: 700,
    })
    ScrollReveal().reveal('.phase3', {
      delay: 400,
      reset: true,
      origin: 'right',
      distance: '20rem',
      duration: 700,
    })
    ScrollReveal().reveal('.phase4', {
      delay: 550,
      reset: true,
      origin: 'right',
      distance: '20rem',
      duration: 700,
    })
  }, [])
  return (
    <RoadmapContainer id="roadmap">
      <Title>Roadmap</Title>
      <MoonImage src={pepeMoon} top="7%" left="70%" />
      <Phase top="20%" left="21%" className="phase1">
        <PhaseContent>
          <h3>Phase 1:</h3>
          <p>- Launch</p>
          <p>- Meme</p>
          <p>- Vibe and HODL</p>
        </PhaseContent>
      </Phase>
      <Phase top="50%" left="34%" className="phase2">
        <PhaseContent>
          <h3>Phase 2:</h3>
          <p>- DEX Listings</p>
          <p>- Marketing</p>
          <p>- Partnerships</p>
        </PhaseContent>
      </Phase>
      <Phase top="20%" left="47%" className="phase3">
        <PhaseContent>
          <h3>Phase 3:</h3>
          <p>- CEX Listings</p>
          <p>- NFT Release</p>
          <p>- Community Incentives</p>
        </PhaseContent>
      </Phase>
      <Phase top="50%" left="60%" className="phase4">
        <PhaseContent>
          <h3>Phase 4:</h3>
          <p>- PEPE goes Multichain</p>
          <p>- Community Voting</p>
          <p>- Global Outreach</p>
        </PhaseContent>
      </Phase>
      <RocketImage src={rocketPepe} top="63%" left="16%" />
    </RoadmapContainer>
  )
}

export default Roadmap
