import styled from 'styled-components'
import babyPepe from '../../../images/characters/baby_pepe.png'
import gigaChadPepe from '../../../images/characters/gigachad_pepe.png'
import pepeWifHat from '../../../images/characters/pepe_wif_hat.png'
import shibaPepe from '../../../images/characters/shiba_pepe.png'
import spidermanPepe from '../../../images/characters/spiderman_pepe.png'
import trumpPepe from '../../../images/characters/trump_pepe.png'
import { NAVIGATION_HEIGHT } from './Navigation'

const AnchorContainer = styled.div``

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  margin-top: ${2 * NAVIGATION_HEIGHT}rem;
  transform: translateY(${NAVIGATION_HEIGHT}rem);
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  @media (max-width: 1440px) {
    padding-bottom: 12px;
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
  }

  @media (max-width: 576px) {
    backdrop-filter: blur(0px);
    background: rgba(0, 0, 0, 0.3);
  }
`

const CharContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto repeat(2, 1fr);
  gap: 20px;
  width: 80%;
  justify-items: center;
  align-items: center;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const CharImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 250px;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
    max-width: 150px;
  }

  @media (max-width: 992px) {
    max-width: 150px;
  }

  @media (max-width: 730px) {
    max-width: 150px;
  }

  @media (max-width: 576px) {
    max-width: 150px;
  }
`

const Title = styled.h1`
  margin-top: ${NAVIGATION_HEIGHT}rem;
  grid-column: span 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 2.5rem;
  color: white;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 730px) {
    grid-column: span 2;
  }

  @media (max-width: 576px) {
  }

  @media (max-width: 400px) {
    grid-column: span 1;
  }
`

const family = [
  { src: babyPepe, alt: 'Baby Pepe' },
  { src: shibaPepe, alt: 'Shiba Pepe' },
  { src: pepeWifHat, alt: 'Pepe Wif Hat' },
  { src: trumpPepe, alt: 'Trump Pepe' },
  { src: gigaChadPepe, alt: 'GigaChad Pepe' },
  { src: spidermanPepe, alt: 'Spiderman Pepe' },
]

const PepeFamily = () => (
  <AnchorContainer>
    <Container id="pepefamily">
      <CharContainer>
        <Grid>
          <Title>Pepe Family</Title>
          {family.map((character, index) => (
            <CharImage key={index} src={character.src} alt={character.alt} />
          ))}
        </Grid>
      </CharContainer>
    </Container>
  </AnchorContainer>
)

export default PepeFamily
