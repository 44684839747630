import { useEffect } from 'react'

import styled from 'styled-components'
import mainBackground from '../../images/main_background.png'
import mainBackgroundMobile from '../../images/main_background_mobile.jpeg'
import Footer from './sections/Footer'
import HomePage from './sections/Homepage'
import HowToBuy from './sections/HowToBuy'
import Navigation, { NAVIGATION_HEIGHT } from './sections/Navigation'
import PepeFamily from './sections/PepeFamily'
import Roadmap from './sections/Roadmap'
import Tokenomics from './sections/Tokenomics'

const MainBackground = styled.div`
  background-image: url(${mainBackground});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: ${2 * NAVIGATION_HEIGHT}rem;
  @media (max-width: 576px) {
    background-image: url(${mainBackgroundMobile});
    padding-left: 0;
    justify-content: center;
    background-position: center calc(50% + ${NAVIGATION_HEIGHT / 2}rem);
  }
`

const Home = () => {
  useEffect(() => {
    const smoothScroll = (targetId: string) => {
      const targetElement = document.querySelector(targetId)
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' })
      }
    }

    const handleLinkClick = (e: Event) => {
      e.preventDefault()
      const targetId = (e.currentTarget as HTMLAnchorElement).getAttribute(
        'href'
      )
      if (targetId) {
        if (targetId === '#') {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        } else {
          smoothScroll(targetId)
        }
      }
    }

    // Smooth scrolling for navigation links
    const links = document.querySelectorAll<HTMLAnchorElement>(
      '.nav-main a[href^="#"]'
    )
    links.forEach((link) => {
      link.addEventListener('click', handleLinkClick)
    })

    // Cleanup event listeners on component unmount
    return () => {
      links.forEach((link) => {
        link.removeEventListener('click', handleLinkClick)
      })
    }
  }, [])

  return (
    <>
      <Navigation />
      <div style={{ overflow: 'hidden' }}>
        <HomePage />
        <Roadmap />
        <MainBackground>
          <Tokenomics />
          <PepeFamily />
          <HowToBuy />
          <Footer />
        </MainBackground>
      </div>
    </>
  )
}

export default Home
