import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  BUY_NOW_URL,
  DEX_SCREENER_URL,
  DEX_TOOLS_URL,
  TELEGRAM_URL,
  TWITTER_URL,
} from '../../../const/url'
import dexscreenerIcon from '../../../images/dexscreener_icon.png'
import dextoolsIcon from '../../../images/dextools_icon.png'
import tokenLogo from '../../../images/logo.png'
import telegramIcon from '../../../images/telegram_icon.png'
import twitterIcon from '../../../images/twitter_icon.png'
export const NAVIGATION_HEIGHT = 4
export const widthToggleMenu = 1280

const NavContainer = styled.div`
  display: flex;
  position: fixed;
  padding: 0 4rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.85)
  );
  height: ${NAVIGATION_HEIGHT}rem;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${widthToggleMenu}px) {
    padding: 0 2rem;
  }
`

const NavTitle = styled.a`
  display: flex;
  font-size: 1.5rem;
  font-weight: 800;
  color: white;
  -webkit-background-clip: text;
  background-clip: text;
  align-items: center;
  transition: transform 0.3s ease-in-out;

  &:hover,
  &:focus {
    transform: scale(1.05);
  }
`

const TokenIcon = styled.img`
  margin-right: 0.5rem;
  width: 3.5rem;
`

interface NavContentProps {
  isOpen: boolean
}
const NavContent = styled.div<NavContentProps>`
  display: flex;
  align-items: center;
  transition:
    max-height 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    padding-bottom 0.3s ease-in-out;
  max-height: ${(props) =>
    props.isOpen ? '30rem' : `${NAVIGATION_HEIGHT}rem`};
  overflow: hidden;

  @media (max-width: ${widthToggleMenu}px) {
    flex-direction: column;
    width: 100%;
    background: rgba(0, 0, 0, 0.85);
    position: absolute;
    top: ${NAVIGATION_HEIGHT}rem;
    left: 0;
    padding-bottom: ${(props) => (props.isOpen ? '1rem' : '0')};
    max-height: ${(props) =>
      props.isOpen ? '100vh' : '0'}; /* Full height when open */
  }
`

const NavMenuItems = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 5rem;

  @media (max-width: ${widthToggleMenu}px) {
    flex-direction: column;
    gap: 1rem;
    margin-right: 0;
  }
`

const NavItem = styled.a`
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition:
    color 0.3s ease-in-out,
    background-color 0.3s ease-in-out,
    font-weight 0.3s ease-in-out,
    transform 0.3s ease-in-out;

  &:hover,
  &:focus {
    font-weight: bold;
    transform: scale(1.1);
  }
`

const NavIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: ${widthToggleMenu}px) {
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }
`

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const Icon = styled.img`
  width: 2rem;
  transition: transform 0.3s ease-in-out;

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
`

const BuyButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 2rem;
  color: black;
  background: white;
  border: 0.0625rem solid white; /* 1px to rem */
  border-radius: 1.25rem;
  transition:
    background 0.3s ease-in-out,
    color 0.3s ease-in-out;

  &:hover,
  &:focus {
    background: #ffffffc8;
    color: black;
  }
`

const MenuToggle = styled.button<{ isOpen: boolean }>`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: ${widthToggleMenu}px) {
    display: flex;
  }

  span {
    width: 1.5rem;
    height: 0.2rem;
    background: white;
    margin: 0.2rem 0;
    transition:
      transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out;

    &:nth-child(1) {
      transform: ${(props) =>
        props.isOpen ? 'rotate(45deg) translate(0.5rem, 0.5rem)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      opacity: ${(props) => (props.isOpen ? '0' : '1')};
    }

    &:nth-child(3) {
      transform: ${(props) =>
        props.isOpen
          ? 'rotate(-45deg) translate(0.5rem, -0.5rem)'
          : 'rotate(0)'};
    }
  }
`

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false)
  const navRef = useRef<HTMLDivElement>(null)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (navRef.current && !navRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [])

  const handleItemClick = () => {
    setIsOpen(false)
  }

  return (
    <NavContainer ref={navRef}>
      <NavTitle href="#" onClick={handleItemClick}>
        <TokenIcon src={tokenLogo} alt="PEPEVERSE" />
        Pepeverse
      </NavTitle>
      <MenuToggle onClick={toggleMenu} isOpen={isOpen}>
        <span />
        <span />
        <span />
      </MenuToggle>
      <NavContent isOpen={isOpen}>
        <NavMenuItems>
          <NavItem href="#" onClick={handleItemClick}>
            Home
          </NavItem>
          <NavItem href="#roadmap" onClick={handleItemClick}>
            Roadmap
          </NavItem>
          <NavItem href="#tokenomics" onClick={handleItemClick}>
            Tokenomics
          </NavItem>
          <NavItem href="#pepefamily" onClick={handleItemClick}>
            PEPE family
          </NavItem>
          <NavItem href="#howtobuy" onClick={handleItemClick}>
            How to buy
          </NavItem>
          {DEX_SCREENER_URL !== '' && (
            <NavItem href={DEX_SCREENER_URL} onClick={handleItemClick}>
              Chart
            </NavItem>
          )}
          {/* <NavItem href="#faq">FAQ</NavItem> */}
        </NavMenuItems>
        <NavIcons>
          <SocialIcons>
            <a href={TELEGRAM_URL} target="_blank" onClick={handleItemClick}>
              <Icon src={telegramIcon} alt="Telegram" />
            </a>
            <a href={TWITTER_URL} target="_blank" onClick={handleItemClick}>
              <Icon src={twitterIcon} alt="Twitter" />
            </a>
            {DEX_SCREENER_URL !== '' && (
              <a
                href={DEX_SCREENER_URL}
                target="_blank"
                onClick={handleItemClick}
              >
                <Icon src={dexscreenerIcon} alt="Twitter" />
              </a>
            )}
            {DEX_TOOLS_URL !== '' && (
              <a href={DEX_TOOLS_URL} target="_blank" onClick={handleItemClick}>
                <Icon src={dextoolsIcon} alt="Twitter" />
              </a>
            )}
          </SocialIcons>
          {BUY_NOW_URL !== '' ? (
            <a href={BUY_NOW_URL} target="_blank">
              <BuyButton>Buy Now !</BuyButton>
            </a>
          ) : (
            <BuyButton>Coming Soon !</BuyButton>
          )}
        </NavIcons>
      </NavContent>
    </NavContainer>
  )
}

export default Navigation
