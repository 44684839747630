export const TWITTER_URL = 'https://x.com/pepeverse_coin'

export const TELEGRAM_URL = 'https://t.me/pepeverse_official'

export let BUY_NOW_URL =
  'https://raydium.io/swap/?outputMint=A6KvZ5koPzHsggJdp3PrHBjTRTuDNVCY2zxwxWevjP5g&inputMint=sol'

export let DEX_TOOLS_URL =
  'https://www.dextools.io/app/en/solana/pair-explorer/DAW6o9NcuATRD17MjajMFwK45hbMouJcgHV3j2of9j2j?t=1717083740845'

export let DEX_SCREENER_URL =
  'https://dexscreener.com/solana/daw6o9ncuatrd17mjajmfwk45hbmoujcghv3j2of9j2j'
